import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import "@/styles/index.less";
import store from "./store";
import { initMenu } from "./router/tools";
import install from "./install";
// import Viewer from 'v-viewer'
// import 'viewerjs/dist/viewer.css'
Vue.use(install);
// Vue.use(Viewer);

Vue.config.productionTip = false;

Promise.allSettled([initMenu(), store.dispatch("auth/getAccountInfo")]).then(() => {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
});
