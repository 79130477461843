import { DeepPartial, PartialResponse } from "@/utils/type";
import { axios, axiosAccount, axiosNoToken } from ".";
import { Menu, MenuType } from "@/types";
import { store } from "@/store/localstorage";

export async function getMenu() {
  function parse(menu?: DeepPartial<menuDTO>): Menu {
    let extJson: { redirect?: string; eName?: string; needLogin?: boolean };
    try {
      extJson = menu?.menuJson ? JSON.parse(menu?.menuJson) : {};
    } catch (error) {
      console.error(error);
      extJson = {};
    }
    return {
      id: menu?.buttonCode ?? "",
      name: menu?.menuButtonFlag === MenuType.frame ? undefined : menu?.name ?? "",
      path: menu?.path ?? "",
      component: menu?.component ?? "",
      children: menu?.children?.map(parse) ?? [],
      redirect: extJson.redirect,
      showFlag: menu?.showFlag ?? true,
      meta: {
        type: menu?.menuButtonFlag ?? MenuType.normal,
        name: menu?.name ?? "",
        eName: extJson.eName ?? "",
        needLogin: extJson.needLogin ?? false,
      },
    };
  }
  const menus = await fetchMenu().then((data) => {
    return data?.menuTrees?.map(parse);
  });

  return menus ?? [];
}

export async function login() {
  return "token";
}

interface menuDTO {
  buttonCode: string;
  name: string;
  component: string;
  path: string;
  showFlag: boolean;
  menuJson: string;
  menuButtonFlag: MenuType;
  children: menuDTO[];
}

export async function fetchMenu() {
  return axiosNoToken.post<
    unknown,
    PartialResponse<{
      menuTrees: menuDTO[];
    }>
  >("websiteNotice/queryMenuTree");
}

export async function getWXQrCode(phone?: string) {
  return axiosAccount.get<unknown, { content: Blob; scenestr: string }>(
    `wx/pc/qrcode?type=${phone ? 1 : 0}_${phone ? `&phone=${phone}` : ""}`,
    {
      responseType: "blob",
    }
  );
}

export async function getCaptcha(phone: string) {
  return axiosNoToken.get<unknown, Blob>(`phone/captcha?phone=${phone}`, {
    responseType: "blob",
  });
}

/**
 * 微信绑定时发送验证码
 * @param phone
 * @returns
 */
export function sendSms(phone: string) {
  return axios.post<unknown, Blob>(`pc/bindSms?phone=${phone}`);
}

/**
 * 手机绑定时发送验证码
 * @param phone
 * @returns
 */
export function sendPhoneSms(phone: string) {
  return axiosNoToken.post<unknown, Blob>(`pc/loginSms?phone=${phone}`);
}

export enum LoginState {
  /** 登录中 */
  loading = "-2",
  /** 失败 */
  fail = "-1",
  /** 成功 */
  success = "1",
  /** 需要绑定微信 */
  needWx = "2",
  /** 需要绑定手机 */
  needPhone = "3",
}
export interface Login {
  accountId: string;
  loginState: LoginState;
  openid: string;
  phone: string;
  sceneStr: string;
  token: string;
}
/**
 * 微信扫码登录
 * @param sceneStr
 * @param phone
 * @returns
 */
export async function validateQrCode(sceneStr: string) {
  return axiosAccount.post<unknown, PartialResponse<Login>>(`wx/pc/login?sceneStr=${sceneStr}`);
}

export enum BindState {
  loading = "I",
  success = "Y",
  fail = "N",
}
export interface BindLogin {
  state: BindState;
}
/**
 * 验证码绑定微信
 * @param sceneStr
 * @param phone
 * @returns
 */
export async function validateBindQrCode(sceneStr: string, phone: string) {
  return axiosAccount.post<unknown, PartialResponse<BindLogin>>(`wx/pc/queryBind?sceneStr=${sceneStr}&phone=${phone}`);
}

/**
 * 验证手机
 * @deprecated
 * @param accountId
 * @returns
 */
export async function checkPhone(accountId: string) {
  return axios.post<unknown, boolean>(`pc/checkPhone?accountId=${accountId}`);
}

interface WxBindPhoneQuery {
  accountId: string;
  code: string;
  phone: string;
  smsCode: string;
}
/**
 * 微信登录，绑定手机
 * @param query
 * @returns
 */
export function fetchWxBindPhone(query: WxBindPhoneQuery) {
  return axiosNoToken.post<unknown>(
    `pc/wxbind?accountId=${query.accountId}&code=${query.code}&phone=${query.phone}&smsCode=${query.smsCode}`
  );
}

interface LoginPhoneQuery {
  code: string;
  phone: string;
  smsCode: string;
}
/**
 * 手机验证码登录
 * @param query
 * @returns
 */
export function fetchPhoneLogin(query: LoginPhoneQuery) {
  return axiosNoToken.post<unknown, PartialResponse<Login>>(
    `pc/phoneLogin?code=${query.code}&phone=${query.phone}&smsCode=${query.smsCode}`
  );
}

interface Register {
  code: string;
  password: string;
  phone: string;
  smsCode: string;
}
/**
 * 注册
 * @param body
 * @returns
 */
export function fetchRegister(body: Register) {
  return axiosNoToken.post<unknown, PartialResponse<Login>>(`phone/regist`, body);
}

interface PassLogin {
  code: string;
  pwd: string;
  phone: string;
}
/**
 * 手机密码登录
 * @param body
 * @returns
 */
export function fetchPassLogin(body: PassLogin) {
  return axiosNoToken.post<unknown, PartialResponse<Login>>(
    `pc/pwdLogin?code=${body.code}&phone=${body.phone}&pwd=${body.pwd}`,
    body
  );
}

/**
 * 获取账号信息
 * @returns
 */
export async function fetchAccountInfo() {
  const accoutId = await store.getItem("accountid");
  if (!accoutId) {
    return;
  }
  return axios.get<
    unknown,
    PartialResponse<{
      accountUserId: string;
      nickname: string;
      headimgurl: string;
    }>
  >(`/account/queryUser/${accoutId}`);
}
