




































import Container from "@/components/Container.vue";
import Nav from "@/components/Nav.vue";
import WX from "./WX.vue";
import { store } from "@/store/localstorage";
import { ref } from "vue-demi";
import SmsCode from "./SmsCode.vue";
import Register from "./Register.vue";
import Password from "./Password.vue";
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  store.removeItem("token");
  const loginType = ref("1");
  const isLogin = ref(true);

  function toRegister() {
    isLogin.value = false;
  }

  function toLogin() {
    isLogin.value = true;
  }

  function onRegisterFinish(bind: boolean) {
    isLogin.value = true;

    if (bind) {
      loginType.value = "1";
    } else {
      loginType.value = "3";
    }
  }

  return {
    loginType,
    isLogin,
    toRegister,
    toLogin,
    onRegisterFinish
  };
};

__sfc_main.components = Object.assign({
  Container,
  Nav,
  WX,
  SmsCode,
  Password,
  Register
}, __sfc_main.components);
export default __sfc_main;
