<template>
  <div :class="fullScreen ? 'nh-w-full' : 'nh-container'">
    <slot></slot>
  </div>
</template>

<script >
const __sfc_main = {};
__sfc_main.props = {
  fullScreen: {
    type: Boolean,
    default: false
  }
};
export default __sfc_main;
</script>

<style lang="less">
// .nh-container {
//   padding: 0;
// }
</style>
