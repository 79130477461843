import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Login from "@/views/Login/index.vue";
import { store } from "@/store/localstorage";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err: any) => err);
};

// const pRoutes = pages.filter((item) => item. !== "home");

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/login",
    component: Login,
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior() {
    return { x: 0, y: 0, behavior: "smooth" };
  },
});

router.beforeEach((to, from, next) => {
  if (to.meta?.needLogin) {
    store.getItem("token").then((token) => {
      if (token) {
        next();
      } else {
        next("/login");
      }
    });
  } else {
    next();
  }
});

export default router;
