




















import { Menu } from "@/types";
import { useRouter } from "@/utils/composition";
import { StyleValue } from "@vue/runtime-dom";
const __sfc_main = {};
__sfc_main.props = {
  index: {
    key: "index",
    required: false,
    type: Number,
    default: 0
  },
  options: {
    key: "options",
    required: false,
    type: Array,
    default: () => []
  },
  itemStyles: {
    key: "itemStyles",
    required: false,
    type: null,
    default: undefined
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const router = useRouter();

  function toPage(path: string) {
    router.push({
      path
    });
  }

  return {
    toPage
  };
};

export default __sfc_main;
