
























import { computed } from "vue-demi";
import store from "@/store";
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const themeCofig = computed(() => store.state.basic.themeCofig);
  return {
    themeCofig
  };
};

export default __sfc_main;
