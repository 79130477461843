












import Header from "@/components/Header.vue";
import Container from "@/components/Container.vue";
import ArticleList from "@/components/ArticleList/index.vue";
import Footer from "@/components/Footer/index.vue";
const __sfc_main = {
  beforeRouteEnter(to, from, next) {
    console.log(from, "from--站内搜索独享守卫");
    next();
  }

};

__sfc_main.setup = (__props, __ctx) => {
  return {};
};

__sfc_main.components = Object.assign({
  Header,
  Container,
  ArticleList,
  Footer
}, __sfc_main.components);
export default __sfc_main;
