import Vue from "vue";
import Vuex from "vuex";
import modules from "./modules";
import { Auth } from "./modules/auth";
import { Basic } from "./modules/basic";
import { BindUser } from "./modules/bindUser";

Vue.use(Vuex);

export default new Vuex.Store<{
  auth: Auth;
  basic: Basic;
  bindUser: BindUser;
}>({
  modules,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any);
