// import { store as storage } from "@/store/localstorage";
const bindUserList = "bindUserList";
const accountInfo = "accountInfo";
const friendlyLink = "friendlyLink";

/* // 保存绑定用户
export function saveBindUserList(info) {
  console.log(info, 'saveBindUserList');
  storage.setItem(bindUserList, info);
  return info;
}

export function loadBindUserList() {
  return storage.getItem(bindUserList);
} */

// 保存绑定用户
export function saveBindUserList(info) {
  localStorage.setItem(bindUserList, JSON.stringify(info));
  return info;
}

// 获取绑定用户
export function loadBindUserList() {
  return JSON.parse(localStorage.getItem(bindUserList)) || [];
}

// 保存登录信息
export function saveAccountInfo(info) {
  localStorage.setItem(accountInfo, JSON.stringify(info));
  return info;
}

// 获取登录信息
export function loadAccountInfo() {
  return JSON.parse(localStorage.getItem(accountInfo)) || [];
}

// 保存友情链接
export function saveFriendlyLink(links) {
  localStorage.setItem(friendlyLink, JSON.stringify(links));
  return links;
}

// 获取友情链接
export function loadFriendlyLink() {
  return JSON.parse(localStorage.getItem(friendlyLink)) || [];
}
