








import Header from "@/components/Header.vue";
import Footer from "@/components/Footer/index.vue";
import { fetchArticle } from "@/api/article";
import { useRoute } from "@/utils/composition";
import { useAsyncState } from "@vueuse/core";
import { computed, watch } from "vue-demi";
import dayjs from "dayjs";
import Preview from "@/components/Preview.vue";
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const route = useRoute();
  const {
    state,
    execute
  } = useAsyncState(fetchArticle, {
    title: "",
    content: "",
    id: "",
    photo: "",
    releaseTime: ""
  }, {
    immediate: false
  });
  const date = computed(() => {
    return state.value.releaseTime ? dayjs(state.value.releaseTime).format("YYYY-MM-DD") : "";
  });
  watch(() => route.value.params.id, () => {
    execute(0, ((route.value.params.id as unknown) as string));
  }, {
    immediate: true
  });
  return {
    state,
    date
  };
};

__sfc_main.components = Object.assign({
  Header,
  Preview,
  Footer
}, __sfc_main.components);
export default __sfc_main;
