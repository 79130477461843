




























import ListItem from "@/components/ListItem/index.vue";
import { useFetchList, useSearchFetchList, useGoPage } from "@/utils/composition";
import { ContentType } from "@/types";
import { useRoute } from "@/utils/composition";
import store from "@/store";
import { watch } from "vue-demi";
interface Item {
  type: ContentType;
  id: string;
  content: string;
  path: string;
}
const __sfc_main = {};
__sfc_main.props = {
  code: {
    key: "code",
    required: false,
    type: String,
    default: ""
  },
  path: {
    key: "path",
    required: false,
    type: String,
    default: ""
  },
  showImage: {
    key: "showImage",
    required: false,
    type: Boolean,
    default: false
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const route = useRoute();
  console.log(route.value.params.keyword, "components-ArticleList-route-params");
  console.log(props.code, "components-ArticleList-props.code");
  console.log(store.state.auth.searchValue, "components-ArticleList-searchValue"); // const data = props.code
  //   ? reactive(useFetchList(props.code))
  //   : reactive(useSearchFetchList(() => store.state.auth.searchValue));

  const data = props.code ? useFetchList(props.code) : useSearchFetchList(() => store.state.auth.searchValue);
  console.log(data, "components-ArticleList-data");
  const goPage = useGoPage();

  function link(item: Partial<Item>) {
    console.log(item, "components-ArticleList-link-item");

    if (item.type === ContentType.text) {
      goPage(`${props.path ? props.path : item.path ? item.path : "/article"}/${item.id}`);
    } else {
      window.open(item.content);
    }
  }

  watch(() => store.state.auth.searchValue, (val, oldVal) => {
    console.log(val, oldVal, "components-ArticleList-searchValue监听");

    if (val && oldVal && val !== oldVal) {
      data.page.value = 1;
      data.onChange();
    }
  }, {
    deep: true,
    immediate: true
  });
  return {
    store,
    props,
    route,
    data,
    link
  };
};

__sfc_main.components = Object.assign({
  ListItem
}, __sfc_main.components);
export default __sfc_main;
