

























































































import Nav from "@/components/Nav.vue";
import Container from "@/components/Container.vue";
import Banner from "@/components/Banner.vue";
import Board from "@/components/Board.vue";
import Breadcrumb from "./Breadcrumb/index.vue";
import BreadcrumbItem from "./Breadcrumb/BreadcrumbItem.vue";
import Icon from "./Icon/index.vue";
import { useGoPage, useRoute, useRouter } from "@/utils/composition";
import { computed, ref } from "vue-demi";
import { Fragment } from "vue-fragment";
import { store as localstorage } from "@/store/localstorage";
import store from "@/store";
import { Modal } from "ant-design-vue";
const __sfc_main = {};
__sfc_main.props = {
  showBoard: {
    key: "showBoard",
    required: false,
    type: Boolean,
    default: true
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const route = useRoute();
  const router = useRouter();
  const hasLogin = ref(true);
  localstorage.getItem("token").then(t => {
    if (t) {
      hasLogin.value = true;
    } else {
      hasLogin.value = false;
    }
  });
  const goPage = useGoPage(); // eslint-disable-next-line

  function chooseMenu(e: any) {
    console.log(e.key, "chooseMenu");

    switch (e.key) {
      case "2":
        goPage("/online/userCenter");
        break;

      case "3":
        logout();
        break;
    }
  }

  function logout() {
    Modal.confirm({
      title: "注意",
      content: "是否登出",

      onOk() {
        Promise.all([localstorage.removeItem("token"), localstorage.removeItem("accountid")]).then(() => {
          store.commit("auth/setCleanInfo");
          hasLogin.value = false;
          goPage("/login");
        });
      }

    });
  }

  const firstName = computed(() => route.value.matched?.[0]?.name ?? "");
  const firstRoute = computed(() => route.value.matched?.[0]?.path ?? "");
  const secondName = computed(() => route.value.matched?.[1]?.name ?? "");
  const menuVisible = ref(false);

  function toggleMenu() {
    menuVisible.value = true;
  }

  const menu = ref(null);

  function onClose() {
    menuVisible.value = false;
  }

  console.log(route.value, "Header--onFirstItemClick--route.value");

  function onFirstItemClick() {
    if (route.value.matched.length > 2) {
      let lastPath = route.value.matched[route.value.matched.length - 1].path;
      let prePath = route.value.matched[route.value.matched.length - 2].path;
      /* 获取最里层路由去掉上一层路由后是不是只剩参数，不是 /:，就代表不是只剩参数
      如：网上营业厅-客户中心-详情，path: '/online/main'、path: '/online/waterCharge'、path: '/online/waterCharge/detail/:id'，
      不能跳转到 /online/waterCharge，应该跳转 /online/waterCharge/userlist */

      let removePath = lastPath.replace(prePath, "").slice(0, 2);
      console.log(removePath, "removePath"); // 判断除客户中心详情页以外，新闻详情返回上级列表

      let firstFlag = lastPath.includes(prePath) && removePath === "/:" && Object.keys(route.value.params).length; // 判断进入列表页，切换后 go(-1)，未切换不变
      // 如第一次点进党群工作，tab栏位于企业党建，点击面包屑党群工作
      // /party/construct 和 /party/construct/

      let secondFlag = prePath === lastPath.slice(0, lastPath.length - 1);
      console.log(secondFlag, "secondFlag");

      if (firstFlag) {
        goPage(route.value.matched[route.value.matched.length - 2].path);
      } else if (!secondFlag) {
        router.go(-1);
      }
    } else {
      goPage(firstRoute.value);
    }
  }

  const pages = store.state.auth.menu.filter(item => !!item.showFlag).map(item => {
    return {
      name: item.name,
      path: item.path
    };
  });

  function linkToPage(path?: string) {
    goPage(path ?? "/");
    menuVisible.value = false;
  }

  const searchText = ref("");
  searchText.value = route.value.params.keyword || "";

  function onSearchBlur(event: any) {
    console.log(event, event.target.value, "onSearchBlur");

    if (searchText.value) {
      store.commit("auth/setSearchValue", searchText.value);
      goPage(`/siteSearch/${searchText.value}`);
    }
  }

  return {
    store,
    hasLogin,
    goPage,
    chooseMenu,
    firstName,
    secondName,
    menuVisible,
    toggleMenu,
    menu,
    onClose,
    onFirstItemClick,
    pages,
    linkToPage,
    searchText,
    onSearchBlur
  };
};

__sfc_main.components = Object.assign({
  Fragment,
  Container,
  Nav,
  Banner,
  Board,
  Breadcrumb,
  BreadcrumbItem,
  Icon
}, __sfc_main.components);
export default __sfc_main;
