<script lang="jsx">
import { defineComponent, useSlots } from "vue-demi";

export default defineComponent({
  props: {
    href: {
      type: String,
    },
  },
  setup() {
    const defaultSlot = useSlots()?.default;

    return {
      defaultSlot,
    };
  },
  render() {
    return (
      <span
        class={[
          "nh-text-lg",
          { "nh-text-primary": this.$props.href == undefined, "nh-cursor-pointer": this.$props.href != undefined },
        ]}
      >
        {this.defaultSlot && this.defaultSlot()}
      </span>
    );
  },
});
</script>
