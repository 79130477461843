









import { ref } from "vue-demi";
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const msg = ref<string | number>("获取验证码");
  const enable = ref(true);

  function cooldown() {
    if (enable.value) {
      enable.value = false;
      msg.value = 60;
      setInterval(() => {
        if (typeof msg.value === "number") {
          if (msg.value > 0) {
            msg.value = msg.value - 1;
          } else {
            enable.value = true;
            msg.value = "获取验证码";
          }
        }
      }, 1000);
    }
  }

  return Object.assign({
    msg,
    enable
  }, {
    cooldown
  });
};

export default __sfc_main;
