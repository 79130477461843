













































import { getCaptcha, Login, fetchRegister, sendPhoneSms } from "@/api/auth";
import { reactive, ref } from "vue-demi";
import CoolDown from "./CoolDown.vue";
import { message } from "ant-design-vue";
import PasswordInput from "./PasswordInput.vue";
import WXScan from "./WXScan.vue";
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const cooldown = ref<InstanceType<typeof CoolDown> | null>(null);
  const emit = __ctx.emit;
  const state = reactive({
    step: "1",
    // '1' | '2'
    phone: (undefined as string | undefined),
    isPhoneValid: false,
    password: (undefined as string | undefined),
    smsCode: (undefined as string | undefined),
    code: (undefined as string | undefined),
    codeImg: (null as null | string),
    submitLoading: false,
    data: ({} as Partial<Login>)
  });

  function isValidMobile(num: string) {
    return /1\d{10}/.test(num);
  }

  function isValidPass(str: string) {
    return /.{6,}/.test(str);
  }

  function onPhoneChange() {
    const phone = state.phone ?? "";

    if (isValidMobile(phone)) {
      state.isPhoneValid = true;
      setCaptcha(phone);
    } else {
      state.isPhoneValid = false;
    }
  }
  /** 获取验证码 */


  async function setCaptcha(phone: string) {
    const data = await getCaptcha(phone);
    state.codeImg = window.URL.createObjectURL(data);
  }

  function refreshCodeImg() {
    setCaptcha(state.phone ?? "");
  }
  /** 获取短信验证码 */


  function getCode() {
    const phone = state.phone ?? "";

    if (isValidMobile(phone)) {
      cooldown.value?.cooldown();
      sendPhoneSms(phone);
    } else {
      message.warn("请输入正确的手机号码");
    }
  }
  /** 绑定手机号码 */


  function submit() {
    if (!isValidMobile(state.phone || "")) {
      message.warning("请输入正确的手机号码");
      return;
    }

    if (!isValidPass(state.password || "")) {
      message.warning("输入密码至少6位");
      return;
    }

    if (!state.code) {
      message.warning("图片验证码不能为空");
      return;
    }

    if (!state.smsCode) {
      message.warning("短信验证码不能为空");
      return;
    }

    state.submitLoading = true;
    fetchRegister({
      password: state.password || "",
      code: state.code,
      phone: state.phone || "",
      smsCode: state.smsCode
    }).then(() => {
      state.step = "2";
    }).catch(() => {// todo
    }).finally(() => {
      state.submitLoading = false;
    });
  }

  function onSuccess() {
    emit("done", true);
  }

  function skip() {
    emit("done", false);
  }

  return {
    cooldown,
    state,
    onPhoneChange,
    refreshCodeImg,
    getCode,
    submit,
    onSuccess,
    skip
  };
};

__sfc_main.components = Object.assign({
  PasswordInput,
  CoolDown,
  WXScan
}, __sfc_main.components);
export default __sfc_main;
