



























import { getCaptcha, Login, fetchPassLogin } from "@/api/auth";
import router from "@/router";
import { store } from "@/store/localstorage";
import VuexStore from "@/store";
import { message } from "ant-design-vue";
import { reactive } from "vue-demi";
import PasswordInput from "./PasswordInput.vue";
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const state = reactive({
    step: "1",
    // '1' | '2'
    data: ({} as Partial<Login>),
    phone: (undefined as string | undefined),
    password: (undefined as string | undefined),
    isPhoneValid: false,
    code: (undefined as string | undefined),
    codeImg: (null as null | string),
    submitLoading: false
  });

  function isValidMobile(num: string) {
    return /1\d{10}/.test(num);
  } // function isValidPass(str: string) {
  //   return /.{6,}/.test(str);
  // }


  function onPhoneChange() {
    const phone = state.phone ?? "";

    if (isValidMobile(phone)) {
      state.isPhoneValid = true;
      setCaptcha(phone);
    } else {
      state.isPhoneValid = false;
    }
  }
  /** 获取验证码 */


  async function setCaptcha(phone: string) {
    const data = await getCaptcha(phone);
    state.codeImg = window.URL.createObjectURL(data);
  }

  function refreshCodeImg() {
    setCaptcha(state.phone ?? "");
  }
  /** 绑定手机号码 */


  function submit() {
    if (!isValidMobile(state.phone || "")) {
      message.warning("请输入正确的手机号码");
      return;
    }

    if (!state.password) {
      message.warning("密码不能为空");
      return;
    }

    if (!state.code) {
      message.warning("验证码不能为空");
      return;
    }

    state.submitLoading = true;
    fetchPassLogin({
      pwd: state.password,
      code: state.code,
      phone: state.phone || ""
    }).then(data => {
      store.setItem("token", data?.token);
      store.setItem("accountid", data?.accountId);
      state.submitLoading = false;
      goHome();
    }).finally(() => {
      state.submitLoading = false;
    });
  }

  function goHome() {
    VuexStore.dispatch("auth/getAccountInfo").then(() => {
      router.push("/");
    });
  }

  return {
    state,
    onPhoneChange,
    refreshCodeImg,
    submit
  };
};

__sfc_main.components = Object.assign({
  PasswordInput
}, __sfc_main.components);
export default __sfc_main;
