



const __sfc_main = {};
__sfc_main.props = {
  type: {
    key: "type",
    required: true,
    type: String
  }
};
export default __sfc_main;
