











































































































































import Container from "../Container.vue";
import Backgournd from "./Backgournd.vue";
import Card from "./Card/index.vue";
import CardItem from "./Card/CardItem.vue";
import { useGoPage } from "@/utils/composition";
import store from "@/store";
import { computed, reactive } from "vue-demi";
import { Modal } from "ant-design-vue";
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const info = computed(() => store.state.basic.info);
  const textConfig = computed(() => store.state.basic.textConfig);
  const linkConfig = computed(() => store.state.basic.linkConfig);
  const goPage = useGoPage(); // const selectWebsite = ref("");

  const state = reactive({
    activeIndex: -1
  });
  /* function onLink(e: Event) {
    const link = (e.target as HTMLInputElement).value;
    window.open(link);
  } */
  // eslint-disable-next-line

  function unfoldLink(item: any, index: number) {
    console.log(item, "unfoldLink");
    state.activeIndex = index;
  } // eslint-disable-next-line


  function chooseLink(e: any) {
    console.log(e.key, "chooseLink"); // window.location.href = e.key;

    Modal.confirm({
      title: "注意",
      content: "您访问的链接即将离开”鄂州水务集团有限公司“门户网站，是否继续？",

      onOk() {
        window.open(e.key);
      }

    });
  }

  return {
    info,
    textConfig,
    linkConfig,
    goPage,
    state,
    unfoldLink,
    chooseLink
  };
};

__sfc_main.components = Object.assign({
  Backgournd,
  Container,
  Card,
  CardItem
}, __sfc_main.components);
export default __sfc_main;
