
















const __sfc_main = {};
__sfc_main.props = {
  title: {
    key: "title",
    required: true,
    type: String
  }
};
export default __sfc_main;
