






import { companyIcon } from "@/config";
import store from "@/store";
import { useRoute } from "@/utils/composition";
import { computed } from "vue-demi";
import Tab from "./Tab/index.vue";
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const route = useRoute();
  const pages = computed(() => store.state.auth.menu.filter(item => item.showFlag));
  const index = computed(() => {
    return pages.value.findIndex(page => {
      return route.value.matched.find(match => match.name === page.name);
    });
  });
  return {
    companyIcon,
    pages,
    index
  };
};

__sfc_main.components = Object.assign({
  Tab
}, __sfc_main.components);
export default __sfc_main;
