
















import time from "@/assets/icons/time.png";
import Container from "./Container.vue";
const __sfc_main = {};
__sfc_main.props = {
  title: {
    key: "title",
    required: true,
    type: String
  },
  date: {
    key: "date",
    required: true,
    type: String
  },
  content: {
    key: "content",
    required: true,
    type: String
  }
};

__sfc_main.setup = (__props, __ctx) => {
  return {
    time
  };
};

__sfc_main.components = Object.assign({
  Container
}, __sfc_main.components);
export default __sfc_main;
