/* eslint-disable */
import { fetchBasicInfo, getPublicMemorialCeremony, Gray } from "@/api/basic";
import { Basic as BasicInfo, HeadBannerImg } from "@/types";
import type { Module } from "vuex";
import moment from "moment";
import { saveFriendlyLink, loadFriendlyLink } from "@/utils/cache";

const state = {
  info: {} as BasicInfo,
  isPublicMemorialCeremony: false,
  defaultCofig: {
    themeColor: "#005FA3",
    headBanner: [] as HeadBannerImg[],
    footerBanner: "@/assets/images/bg4.png",
  },
  themeCofig: {
    themeColor: "",
    headBanner: [] as HeadBannerImg[],
    footerBanner: "",
  },
  textConfig: [],
  linkConfig: loadFriendlyLink(),
};
export type Basic = typeof state;

export const auth: Module<typeof state, unknown> = {
  namespaced: true,
  state,
  mutations: {
    setBasicInfo: (state, info) => {
      state.info = info;
    },
    setIsPublicMemorialCeremony: (state, isPublicMemorialCeremony) => {
      state.isPublicMemorialCeremony = isPublicMemorialCeremony;
    },
    setThemeCofig: (state, themeForm) => {
      // console.log(themeForm, "themeForm--setThemeCofig");

      state.themeCofig.themeColor =
        themeForm.find((val: any) => val.label === "主题皮肤")?.value || state.defaultCofig.themeColor;
      state.themeCofig.headBanner =
        themeForm.find((val: any) => val.label === "首页页首图片")?.value || state.defaultCofig.headBanner;
      state.themeCofig.footerBanner =
        themeForm.find((val: any) => val.label === "首页页尾图片")?.value || state.defaultCofig.footerBanner;
    },
    setTextConfig: (state, textConfig) => {
      state.textConfig = textConfig;
    },
    setLinkConfig: (state, linkList) => {
      state.linkConfig = saveFriendlyLink(linkList);
    },
  },
  actions: {
    getBasicInfo({ commit }) {
      fetchBasicInfo().then((data) => {
        commit("setBasicInfo", data);
      });
    },
    getIsPublicMemorialCeremony({ commit }) {
      getPublicMemorialCeremony().then((data) => {
        console.log(JSON.parse(data.config), "主题配置");
        const themeData = JSON.parse(data.config);
        let isNationalMemorialDay: any, inTheTime: any;
        // 第一次循环，处理取配置条件
        themeData
          .find((config: any) => config.app === "PC官网")
          .groups.forEach((groupItem: any) => {
            if (groupItem.groupName === "主题设置") {
              // false 开启公祭日灰色滤镜
              isNationalMemorialDay = groupItem.form.find((val: any) => val.label === "颜色控制")?.value;
              commit("setIsPublicMemorialCeremony", !isNationalMemorialDay);
              // 公祭日不取配置，直接配置灰色主题
              // if (isNationalMemorialDay) return false;
            }

            if (groupItem.groupName === "临时主题") {
              const dateRange = groupItem.form.find((val: any) => val.label === "生效时间")?.value || [];
              // console.log(dateRange, "生效时间");

              if (dateRange.length === 2) {
                const currentDate = moment().format("YYYY-MM-DD");
                const diffStart =
                  moment(dateRange[0], "YYYY-MM-DD").diff(moment(currentDate, "YYYY-MM-DD"), "days") <= 0;
                // console.log(diffStart, "开始时间差");

                const diffEnd = moment(dateRange[1], "YYYY-MM-DD").diff(moment(currentDate, "YYYY-MM-DD"), "days") >= 0;
                // console.log(diffEnd, "结束时间差");

                inTheTime = diffStart && diffEnd; // 是否在生效时间内
              } else {
                inTheTime = false;
              }
            }
          });

        // console.log(inTheTime, "是否在生效时间内");
        if (inTheTime !== undefined && !inTheTime) {
          const list = themeData
            .find((config: any) => config.app === "PC官网")
            .groups.find((groupItem: any) => groupItem.groupName === "常规主题").form;
          commit("setThemeCofig", list);
        }

        if (inTheTime !== undefined && inTheTime) {
          const list = themeData
            .find((config: any) => config.app === "PC官网")
            .groups.find((groupItem: any) => groupItem.groupName === "临时主题").form;
          commit("setThemeCofig", list);
        }

        const otherList = themeData
          .find((config: any) => config.app === "PC官网")
          .groups.find((groupItem: any) => groupItem.groupName === "其他设置").form;
        commit("setTextConfig", otherList);

        const linkList = themeData
          .find((config: any) => config.app === "PC官网")
          .groups.find((groupItem: any) => groupItem.groupName === "友情链接").form;
        commit("setLinkConfig", linkList);
      });
    },
  },
};

export default auth;
