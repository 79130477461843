/*
 * @Date: 2023-05-25 19:52:53
 * @LastEditors: wurui
 * @LastEditTime: 2023-05-25 23:20:44
 * @FilePath: /nethall-web/src/api/index.ts
 */
import createRequest from "@/axios";
import router from "@/router";
import { store } from "@/store/localstorage";
const isProd = process.env.NODE_ENV === "production";
// const ApiPath = "/nethall2.5/dev/api";
const ApiPath = "/dev-ezhou";

export const BASE_URL = `${isProd ? "" : "/api"}${ApiPath}/back`;

export const axiosNoToken = createRequest({
  logout() {
    store.removeItem("token");
    store.removeItem("accountid");
    router.push("/login");
  },
  token: false,
  baseURL: `${isProd ? "" : "/api"}${ApiPath}/back`,
});

export const axios = createRequest({
  logout() {
    store.removeItem("token");
    store.removeItem("accountid");
    router.push("/login");
  },
  baseURL: `${isProd ? "" : "/api"}${ApiPath}/back`,
  token: true,
});

export const axiosAccount = createRequest({
  logout() {
    store.removeItem("token");
    store.removeItem("accountid");
    router.push("/login");
  },
  token: false,
  baseURL: `${isProd ? "" : "/api"}${ApiPath}/publicaccount`,
});

export const axiosElect = createRequest({
  logout() {
    store.removeItem("token");
    store.removeItem("accountid");
    router.push("/login");
  },
  token: false,
  baseURL: `${isProd ? "" : "/api"}${ApiPath}/elect`,
});

export const axiosX = createRequest({
  logout() {
    store.removeItem("token");
    store.removeItem("accountid");
    router.push("/login");
  },
  baseURL: `${isProd ? "" : "/api"}${ApiPath}/back`,
  token: true,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
});
