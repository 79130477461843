











































import { getCaptcha, LoginState, Login, fetchPhoneLogin, sendPhoneSms } from "@/api/auth";
import router from "@/router";
import { store } from "@/store/localstorage";
import VuexStore from "@/store";
import { reactive, ref } from "vue-demi";
import CoolDown from "./CoolDown.vue";
import { message } from "ant-design-vue";
import WXScan from "./WXScan.vue";
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const cooldown = ref<InstanceType<typeof CoolDown> | null>(null);
  const state = reactive({
    step: "1",
    // '1' | '2'
    phone: (undefined as string | undefined),
    // phone: "18616850311",
    isPhoneValid: false,
    smsCode: (undefined as string | undefined),
    code: (undefined as string | undefined),
    codeImg: (null as null | string),
    submitLoading: false,
    data: ({} as Partial<Login>)
  });

  function isValidMobile(num: string) {
    return /1\d{10}/.test(num);
  }

  function onPhoneChange() {
    const phone = state.phone ?? "";

    if (isValidMobile(phone)) {
      state.isPhoneValid = true;
      setCaptcha(phone);
    } else {
      state.isPhoneValid = false;
    }
  }
  /** 获取验证码 */


  async function setCaptcha(phone: string) {
    const data = await getCaptcha(phone);
    state.codeImg = window.URL.createObjectURL(data);
  }

  function refreshcodeImg() {
    setCaptcha(state.phone ?? "");
  }
  /** 获取短信验证码 */


  function getSmsCode() {
    const phone = state.phone ?? "";

    if (isValidMobile(phone)) {
      cooldown.value?.cooldown();
      sendPhoneSms(phone);
    } else {
      message.warn("请输入正确的手机号码");
    }
  }
  /** 绑定手机号码 */


  function submit() {
    if (!isValidMobile(state.phone || "")) {
      message.warning("请输入正确的手机号码");
      return;
    }

    if (!state.code) {
      message.warning("图片验证码不能为空");
      return;
    }

    if (!state.smsCode) {
      message.warning("短信验证码不能为空");
      return;
    }

    state.submitLoading = true;
    fetchPhoneLogin({
      code: state.code,
      phone: state.phone || "",
      smsCode: state.smsCode
    }).then(res => {
      if (res?.loginState === LoginState.success) {
        state.data = res;
        store.setItem("token", res.token);
        store.setItem("accountid", res?.accountId);
        goHome();
        return;
      }

      if (res?.loginState === LoginState.needWx) {
        state.data = res;
        store.setItem("token", res.token);
        store.setItem("accountid", res?.accountId);
        state.step = "2";
        return;
      }

      if (res?.loginState === LoginState.fail) {
        message.warning("登录失败，请稍后再登录");
        return;
      }

      return;
    }).finally(() => {
      state.submitLoading = false;
    });
  }

  function onSuccess() {
    goHome();
  }

  function goHome() {
    VuexStore.dispatch("auth/getAccountInfo").then(() => {
      router.push("/");
    });
  }

  return {
    cooldown,
    state,
    onPhoneChange,
    refreshcodeImg,
    getSmsCode,
    submit,
    onSuccess,
    goHome
  };
};

__sfc_main.components = Object.assign({
  CoolDown,
  WXScan
}, __sfc_main.components);
export default __sfc_main;
