















import { ref } from "vue-demi";
const __sfc_main = {};
__sfc_main.props = {
  value: {
    key: "value",
    required: false,
    type: [String, null],
    default: ""
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const isShowPass = ref(false);

  function toggleVisible() {
    isShowPass.value = !isShowPass.value;
  }

  function onInput(e: Event) {
    emit("input", (e.target as HTMLInputElement).value ?? "");
  }

  return {
    props,
    isShowPass,
    toggleVisible,
    onInput
  };
};

export default __sfc_main;
