<script lang="jsx">
import { defineComponent, useSlots } from "vue-demi";
import { Fragment } from "vue-fragment";

export default defineComponent({
  setup() {
    const defaultSlot = useSlots()?.default;

    return {
      defaultSlot,
    };
  },
  render() {
    const slots = this.defaultSlot();
    return (
      <div class="nh-flex nh-items-center">
        {slots.map((item, i) => (
          <Fragment>
            {item}
            {i < slots.length - 1 ? <span class="nh-px-2">{">"}</span> : null}
          </Fragment>
        ))}
      </div>
    );
  },
});
</script>
