import companyIcon from "@/assets/images/ezhouNewLogo.png";

export const APP_PREFIX = "nh";
export const phone = "88580131";
export const companyName = "福建省石狮供水股份有限公司";
export const address = "福建省石狮供水股份有限公司";
export const customerCenterPhone = "0595-88580131";
export const repairPhone = "0595-88553110";
export const zipCode = "362700";
export const copyRight = "闽ICP备11004420号";

export { companyIcon };
