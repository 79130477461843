import Vue from "vue";
import VueCompositionAPI from "@vue/composition-api";
import Vuex from "vuex";

import {
  Button,
  message,
  Tabs,
  Pagination,
  ConfigProvider,
  Input,
  Icon,
  Drawer,
  Carousel,
  Checkbox,
  Tag,
  Select,
  Radio,
  Upload,
  TimePicker,
  Form,
  Col,
  Row,
  List,
  Card,
  Avatar,
  Table,
  Popconfirm,
  Empty,
  Descriptions,
  Badge,
  Modal,
  Spin,
  FormModel,
  DatePicker,
  Dropdown,
  Menu,
  Space,
  Alert,
  Tooltip,
} from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
function install() {
  Vue.use(Vuex);
  Vue.use(VueCompositionAPI);
  Vue.use(Button);
  Vue.use(Tabs);
  Vue.use(Icon);
  Vue.use(Pagination);
  Vue.use(ConfigProvider);
  Vue.use(Input);
  Vue.use(Drawer);
  Vue.use(Carousel);
  Vue.use(Checkbox);
  Vue.use(Tag);
  Vue.use(Select);
  Vue.use(Radio);
  Vue.use(Upload);
  Vue.use(TimePicker);
  Vue.use(Form);
  Vue.use(Col);
  Vue.use(Row);
  Vue.use(List);
  Vue.use(Card);
  Vue.use(Avatar);
  Vue.use(Table);
  Vue.use(Popconfirm);
  Vue.use(Empty);
  Vue.use(Descriptions);
  Vue.use(Badge);
  Vue.use(Modal);
  Vue.use(Spin);
  Vue.use(FormModel);
  Vue.use(DatePicker);
  Vue.use(Dropdown);
  Vue.use(Menu);
  Vue.use(Space);
  Vue.use(Alert);
  Vue.use(Tooltip);
  Vue.prototype.$message = message;
}

export default install;
