







import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import { computed } from "vue-demi";
import store from "./store";
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  store.dispatch("basic/getBasicInfo");
  store.dispatch("basic/getIsPublicMemorialCeremony");
  const isGray = computed(() => store.state.basic.isPublicMemorialCeremony);
  return {
    zhCN,
    isGray
  };
};

export default __sfc_main;
