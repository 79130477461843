// import bg from "@/assets/images/image-placeholder.png";
import bg from "@/assets/images/default_image.png";
import { PartialResponse } from "@/utils/type";
import { axios } from ".";
import dayjs from "dayjs";
import { ResponseHandleType } from "@/axios";
import { ContentType, List, Pagination } from "@/types";

interface ListItemDto {
  id: string;
  photo: string;
  title: string;
  content: string;
  contentType: ContentType;
  releaseTime: string;
  code: string;
  photoNews: unknown;
}

export async function getList({
  code,
  pagination,
  showAll,
  photoNews,
}: {
  code: string;
  pagination: Pagination;
  showAll?: boolean;
  photoNews: string;
}) {
  const _params = code === "corporate_news" ? { isPhoto: photoNews } : {};
  return axios
    .post<unknown, List<PartialResponse<ListItemDto>>>(
      "websiteNotice/page",
      {
        code, // "party_building",
        size: pagination.size,
        current: pagination.page,
        activeFlag: 1,
        partContentFlag: !showAll,
        isAsc: false,
        field: "releaseTime",
        ..._params,
      },
      {
        dataType: ResponseHandleType.list,
      }
    )
    .then((res: any) => {
      return {
        data:
          res.data?.map((item: any) => {
            return {
              id: item?.id,
              photo: item?.photo,
              img: item?.photo ?? (bg as string),
              day: item?.releaseTime ? dayjs(item?.releaseTime).format("DD") : "",
              monthDay: item?.releaseTime ? dayjs(item?.releaseTime).format("MM.DD") : "",
              year: item?.releaseTime ? dayjs(item?.releaseTime).format("YYYY") : "",
              date: item?.releaseTime ? dayjs(item?.releaseTime).format("YYYY-MM") : "",
              dateYMD: item?.releaseTime ? dayjs(item?.releaseTime).format("YYYY-MM-DD") : "",
              dateYMDhms: item?.releaseTime ? dayjs(item?.releaseTime).format("YYYY-MM-DD hh:mm:ss") : "",
              title: item?.title,
              description: item?.content,
              content: item?.content,
              contentType: item?.contentType ?? 0,
              code: item?.code ?? "",
              photoNews: item?.photoNews ?? null,
            };
          }) ?? [],
        total: res.total,
      };
    });
}

export async function getSearchList({
  content,
  pagination,
  showAll,
}: {
  content: string;
  pagination: Pagination;
  showAll?: boolean;
}) {
  return axios
    .post(
      "websiteNotice/search",
      {
        content,
        size: pagination.size,
        current: pagination.page,
        partContentFlag: !showAll,
      },
      {
        dataType: ResponseHandleType.list,
      }
    )
    .then((res: any) => {
      console.log(res, "res--文章搜索");

      return {
        data:
          res?.data.map((item: any) => {
            return {
              id: item?.id,
              path: item?.photoNews === "photo" ? item?.path + "Img" : item?.path,
              title: item?.title,
              img: item?.photo ?? (bg as string),
              description: item?.content,
              content: item?.content,
              contentType: item?.contentType ?? 0,
              day: item?.releaseTime ? dayjs(item?.releaseTime).format("DD") : "",
              monthDay: item?.releaseTime ? dayjs(item?.releaseTime).format("MM.DD") : "",
              year: item?.releaseTime ? dayjs(item?.releaseTime).format("YYYY") : "",
              date: item?.releaseTime ? dayjs(item?.releaseTime).format("YYYY-MM") : "",
              dateYMD: item?.releaseTime ? dayjs(item?.releaseTime).format("YYYY-MM-DD") : "",
              dateYMDhms: item?.releaseTime ? dayjs(item?.releaseTime).format("YYYY-MM-DD hh:mm:ss") : "",
            };
          }) ?? [],
        total: res.total,
      };
    });
}
