import { fetchAccountInfo } from "@/api/auth";
import { Menu } from "@/types";
import type { Module } from "vuex";
import { store } from "../localstorage";
// import { saveAccountInfo, loadAccountInfo } from "@/utils/cache";
interface Account {
  nickname: string;
  headimgurl: string;
  verifiedFlag: string;
  phoneNumber: string;
}
const state = {
  menu: [] as Menu[],
  accountInfo: undefined as Account | undefined,
  searchValue: "",
};
export type Auth = typeof state;

export const auth: Module<typeof state, unknown> = {
  namespaced: true,
  state,
  mutations: {
    setMenu: (state, menu) => {
      state.menu = menu;
    },
    setInfo(state, info) {
      state.accountInfo = info;
    },
    setCleanMenu: (state) => {
      state.menu = [];
    },
    setCleanInfo(state) {
      state.accountInfo = undefined;
    },
    setSearchValue(state, searchValue) {
      console.log(searchValue, "setSearchValue");

      state.searchValue = searchValue;
    },
  },
  actions: {
    getAccountInfo({ dispatch, commit }) {
      return store
        .getItem("accountid")
        .then(fetchAccountInfo)
        .then((data) => {
          if (data?.accountUserId) {
            commit("setInfo", data);
            dispatch("bindUser/getUserList", null, { root: true });
          }
        });
    },
  },
};

export default auth;
