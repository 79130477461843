










import { computed } from "vue-demi";
import store from "@/store";
import { useRoute } from "@/utils/composition";
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const themeCofig = computed(() => store.state.basic.themeCofig);
  const route = useRoute();
  const firstRoute = route.value.matched?.[0];
  const page = store.state.auth.menu.find(page => page.name === firstRoute?.name);
  const name = page?.name ?? "";
  const eName = page?.meta.eName;
  return {
    themeCofig,
    name,
    eName
  };
};

export default __sfc_main;
