export enum MenuType {
  /** 普通路由 */
  normal = "0",
  /** 按钮 */
  button = "1",
  /** 外部链接 */
  frame = "2",
}

export interface Menu {
  id?: string;
  name?: string;
  path?: string;
  component?: string;
  children?: Menu[];
  redirect?: string;
  showFlag: boolean;
  meta: {
    type: MenuType;
    name: string;
    eName: string;
    needLogin: boolean;
  };
}

export interface List<T> {
  data: T[];
  total: number;
}

export interface Pagination {
  size: number;
  page: number;
}

export enum ContentType {
  text = 0,
  link = 1,
}

export interface Basic {
  companyAddress: string;
  companyName: string;
  customerServiceCenter: string;
  emergencyRepairHotline: string;
  postalCode: string;
  recordNo: string;
  waterSupplyServiceHotline: string;
  linksMap: {
    [key: string]: string;
  };
}

export interface HeadBannerImg {
  name: string;
  uid: string;
  url: string;
}
